/**
 *	Styles
 */

@import '../node_modules/animate.css/animate.css';

// The order of the imports matter!
@import 'styles/index';
@import 'styles/vendors/index';

* {
  white-space: pre-line;
}

// https://fonts.google.com/icons
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 500,
  'GRAD' 0,
  'opsz' 48
}

// Custom styles
// @import 'src/pages/campaigns/products/styles/index'

.tabs {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 4px -2px rgba(0,0,0,0.5);
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: $primary;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &:hover {
      background: darken($primary, 10%);
    }
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .35s;
    }
  }
  &-content {
    max-height: 0;
    padding: 0 1em;
    color: $primary;
    background: white;
    transition: all .35s;
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: $primary;
    cursor: pointer;
    &:hover {
      background: darken($primary, 10%);
    }
  }
}

// :checked
input:checked {
  + .tab-label {
    background: darken($primary, 10%);
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: fit-content;
    padding: 1em;
  }
}
