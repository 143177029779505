//
// Custom
//

@use 'sass:map';

.picture-area {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 24px;
  flex-wrap: wrap;
  overflow: auto;
  height: 100%;

  background: #F8F8FF;
  border: 1px dashed #C7C3C3;
  border-radius: 4px;
}

.image-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.remove-picture {
  border-radius: 24px;
  position: absolute;
  height: 32px;
  width: 32px;
  top: -12px;
  right: -12px;
  color: white;
  background: black;
  border: 0px none;
  align-items: center;
  justify-content: center;
  display: flex;
}

.small-add-picture {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  gap: 4px;

  width: 190px;
  height: 150px;
  background: #F8F8FF;
  border: 1px dashed #7F63F4;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;

}

.square-card {
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  padding: 16px;
  box-shadow: 0.5px 3px 10px rgba(119, 119, 119, 0.1);
  border-radius: 0;
}

.form-check-input:checked {
  background-color: #46BCAA !important;
  border-color: #46BCAA !important;
}

.form-check-input {
  height: 24px !important;
  width: 48px !important;
}

.tooltip-anchor .tooltip-card {
  display: flex;
  flex-direction: column;
  background: #F8FAFC;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 16px;


  visibility: hidden;
  position: absolute;
  z-index: 100;
}

.tooltip-anchor:hover .tooltip-card {
  visibility: visible;
}


.scroll-margin {
	scroll-margin-top: map.get($header, height) + map.get($subheader, height) +
		map.get($page, padding-y);
}

.loading-data-container {
	position: fixed;
	width: 30%;
	// bottom: 55px;
	top: 20px;
	background: white;
	padding: 10px;
	border: gray;
	border-width: 1px;
	opacity: 85%;
	border-style: inherit;
	border-radius: 15px;
	right: 20px;
	//left: 50%;
}

.monospaced {
	font-family: var(--bs-font-monospace)
}

.offer-product-text {
	font-weight: 450;
	font-size: .6vmax;
	line-height: 1.5vmax;
}

.offer-product-image {
	width: 120px;
	height: 120px;
	object-fit: contain;
}

.small-text {
	font-size: 0.5vmax
}
